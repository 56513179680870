import React, { useState, useMemo, useEffect, useCallback, MutableRefObject } from 'react';
import {
  DataGridPro,
  GridColDef,
  GridColumnMenuContainer,
  GridColumnMenuHideItem,
  GridColumnMenuItemProps,
  GridColumnMenuPinningItem,
  GridColumnMenuSortItem,
  GridRowSelectionModel as GridSelectionModel,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  useGridApiContext,
  useGridApiRef,
  GridApi,
} from '@mui/x-data-grid-pro';
import LinearProgress from '@material-ui/core/LinearProgress';
import FooterContainer from './FooterContainer';
import { RequestMetaData, ExternalActionsProps } from './InterfaceDataGridPro';
import { datagridStyles, useStyles, manageColumnsButtons } from './styles';
import { has } from 'lodash';
import { WithSettingStore, inject } from 'stores';
import { observer } from 'mobx-react';
import { Box } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faArrowUp,
  faColumns3,
  faEyeSlash,
  faThumbTack,
} from '@fortawesome/pro-regular-svg-icons';
import { Switch } from 'components/Switch/Switch';
import Button from 'components/Button/Dialog/Button';
import OutlinedInput from 'components/Input/OutlinedInput';
import clsx from 'clsx';
import { Divider, Stack } from '@mui/material';
import ToolbarContainer from './ToolbarContainer';
import useMobileView from 'components/MobileView/useMobileView';
import Title, { ETitleVariant } from 'components/Title/Title/Title';

type sortDirectionType = 'DESC' | 'ASC';

type GridColumns = GridColDef[];
interface DataGridProps extends WithSettingStore {
  columns: GridColumns;
  name?: string;
  fetch?: (
    rmd: RequestMetaData,
  ) => Promise<{ rows: any[]; sortable?: string[]; totalElements: number }>;
  refetchKey?: any;
  sortDirection?: sortDirectionType;
  sortByField?: string;
  disableColumnMenu?: boolean;
  checkboxSelection?: boolean;
  getSelectedRows?: (v: any[]) => void;
  disableSelectionOnClick?: boolean;
  actions?: ExternalActionsProps;
  Toolbar?: React.JSXElementConstructor<any>;
  data?: any[];
  pathname?: string;
  apiRef?: MutableRefObject<GridApi>;
  noRowsMessage?: string | JSX.Element;
  disableToolbar?: boolean;
  disableToolbarColumnsButton?: boolean;
}

const CustomLoadingOverlay: () => JSX.Element = () => (
  <Box style={{ position: 'relative', top: 0, width: '100%', zIndex: 10 }}>
    <LinearProgress />
  </Box>
);

const DataGridInfiniteScroll = ({
  pathname,
  columns,
  name,
  fetch,
  refetchKey,
  sortDirection,
  sortByField,
  getSelectedRows,
  checkboxSelection,
  Toolbar,
  actions,
  data,
  settingStore,
  noRowsMessage,
  disableToolbar,
  disableToolbarColumnsButton,
  ...restProps
}: DataGridProps) => {
  const [loading, setLoading] = useState(false);
  const [loadedRows, setLoadedRows] = useState<any[]>(data || []);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [totalElements, setTotalElements] = useState<number>(data ? data.length : 0);
  const [sortModel, setSortModel] = React.useState<GridSortModel | undefined>(undefined);
  const [sortable, setSortable] = useState<string[]>([]);

  const apiRef = useGridApiRef();

  const classes = useStyles();

  const autoHeight = useMemo(
    () => (totalElements > 15 ? '100vh' : 52 * totalElements + 185),
    [totalElements],
  );

  const mobileView = useMobileView();

  const updatesetRows = useCallback((rows: any[]) => {
    setLoadedRows(rows);
  }, []);

  const updatesetSortable = useCallback((sortables: string[]) => {
    setSortable(sortables);
  }, []);

  const updatesetSortModel = useCallback((sort: GridSortModel) => {
    setSortModel(sort);
  }, []);

  const updatesetTotalElements = useCallback((total: number) => {
    setTotalElements(total);
  }, []);

  const gridColumns: GridColumns = useMemo(() => {
    if (pathname) {
      const sizes = settingStore!.getGrid(pathname);
      if (sizes) {
        return columns.map(({ flex, ...col }) => {
          if (has(sizes, col.field)) {
            const width = Math.max(sizes[col.field], col.minWidth || 0);
            return Object.assign(col, { width });
          }
          return { flex, ...col };
        });
      }
      return columns;
    }
    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, pathname, settingStore, mobileView]);

  const loadServerRows = useCallback(
    async (query: RequestMetaData) => {
      setLoading(true);
      fetch &&
        fetch(query)
          .then((newData) => {
            if (newData) {
              totalElements !== newData.totalElements &&
                updatesetTotalElements(newData.totalElements);

              if (Array.isArray(newData.rows)) {
                const newRows = query?.reload
                  ? [...newData.rows]
                  : [...loadedRows, ...newData.rows];
                updatesetRows(newRows);
              }

              if (Array.isArray(newData.sortable)) {
                updatesetSortable(newData.sortable);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoading(false);
            setRowsPerPage(rowsPerPage + 5);
          });
    },
    [loadedRows, fetch, rowsPerPage, totalElements],
  );

  const handleOnRowsScrollEnd = useCallback(
    async (params) => {
      if (
        ((loadedRows.length < totalElements && loadedRows.length + 5 >= rowsPerPage) ||
          params.reload) &&
        !data
      ) {
        const getSort = () => {
          const isSortModel = Array.isArray(sortModel) && sortModel.length;
          const sortBy = isSortModel ? sortModel[0].field : sortByField || '';
          const sortOrder = isSortModel ? String(sortModel[0].sort).toUpperCase() : sortDirection;

          const isSortable = sortable.includes(sortBy);
          if (!isSortable) return undefined;

          return {
            sortBy,
            sortOrder: sortOrder || 'DESC',
          };
        };
        const query = {
          pagination: {
            take: rowsPerPage,
            skip: params.reload ? 0 : loadedRows.length,
          },
          sort: getSort(),
          ...(params.reload && { reload: true }),
        };

        loadServerRows(query);
      }
    },
    [
      loadedRows,
      totalElements,
      rowsPerPage,
      sortByField,
      sortDirection,
      data,
      loadServerRows,
      sortModel,
      sortable,
    ],
  );

  const handleSelectionChange = useCallback(
    (selectionModel: GridSelectionModel, details: any) => {
      const selectedIDs = new Set(selectionModel);
      const selectedRowData = loadedRows.filter(({ id }) => selectedIDs.has(id));
      getSelectedRows && getSelectedRows(selectedRowData);
    },
    [loadedRows, getSelectedRows],
  );

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer style={{ justifyContent: 'space-between' }}>
        {name && <Title variant={ETitleVariant.TABLE}>{name}</Title>}
        <Box display={'flex'} alignContent={'center'} gridGap={16}>
          {!disableToolbarColumnsButton && (
            <GridToolbarColumnsButton
              disableFocusRipple
              disableTouchRipple
              sx={manageColumnsButtons}
              startIcon={<FontAwesomeIcon icon={faColumns3} fontSize={16} />}
            />
          )}
          <ToolbarContainer {...actions} />
        </Box>
      </GridToolbarContainer>
    );
  };

  const CustomColumnMenu = (props: GridColumnMenuItemProps) => {
    const { hideMenu, colDef, open } = props;
    const handleClick = () => {};
    return (
      <GridColumnMenuContainer
        hideMenu={hideMenu}
        colDef={colDef}
        open={open}
        className={classes.columnMenu}>
        <GridColumnMenuSortItem colDef={colDef} onClick={handleClick} />
        <Divider />
        <GridColumnMenuPinningItem colDef={colDef} onClick={handleClick} />
        <Divider />
        <GridColumnMenuHideItem colDef={colDef} onClick={handleClick} />
      </GridColumnMenuContainer>
    );
  };

  const CustomFooter = () => {
    const count = loadedRows.length;
    if (!count) return null;
    return <FooterContainer total={totalElements} count={count} />;
  };

  const NoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        {noRowsMessage || 'No rows'}
      </Stack>
    );
  };

  const CustomColumnsPanelComponent = (props: any) => {
    const apiContext = useGridApiContext();
    const [filter, setFilter] = useState('');

    let columns = apiContext?.current?.getAllColumns();
    if (filter) {
      columns = columns.filter((c) => c.field.includes(filter));
    }

    const visibleCols = apiContext.current.getVisibleColumns();
    const setColumnVisibility = apiContext?.current?.setColumnVisibility;

    const handleShowAll = () => {
      columns?.forEach((column) => setColumnVisibility(column.field, true));
    };

    const handleHideAll = () => {
      columns?.forEach((column) => setColumnVisibility(column.field, false));
    };

    return (
      <Box width={'100%'} className={classes.manageColumnsWrapper}>
        <Box pl={2} pr={2}>
          <OutlinedInput
            fullWidth
            value={filter}
            label="Find column"
            placeholder="Column title"
            onChange={(event: any) => setFilter(event.currentTarget.value)}
          />
        </Box>
        <Box className={classes.manageColumnsContent}>
          {columns?.map((c, index) => {
            const checked = !!visibleCols.find((cols) => cols.field === c.field);
            return c.hideable ? (
              <Box key={index}>
                <Switch
                  className={classes.switch}
                  checked={checked}
                  onClick={() => setColumnVisibility(c.field, !checked)}
                />{' '}
                {c.headerName}
              </Box>
            ) : null;
          })}
        </Box>
        <Box className={classes.manageColumnsButtons}>
          <Button className={classes.button} variant="outlined" onClick={handleHideAll}>
            Hide all
          </Button>
          <Button className={classes.button} variant="contained" onClick={handleShowAll}>
            Show all
          </Button>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    handleOnRowsScrollEnd({ reload: true });
  }, [refetchKey, sortModel]);

  useEffect(() => {
    !data && updatesetRows([]);

    return () => {
      updatesetRows([]);
      updatesetTotalElements(0);
    };
  }, []);

  return (
    <div style={{ height: autoHeight, width: '100%' }}>
      <DataGridPro
        apiRef={apiRef}
        sx={datagridStyles(disableToolbar)}
        columns={gridColumns}
        rows={loadedRows}
        loading={loading}
        onRowsScrollEnd={handleOnRowsScrollEnd}
        hideFooterPagination
        disableSelectionOnClick
        onRowSelectionModelChange={handleSelectionChange}
        checkboxSelection={checkboxSelection}
        onSortModelChange={(newSortModel) => updatesetSortModel(newSortModel)}
        onColumnWidthChange={(params) => {
          if (pathname) {
            settingStore!.setGrid(pathname, params);
          }
        }}
        {...restProps}
        slots={{
          loadingOverlay: CustomLoadingOverlay,
          noRowsOverlay: NoRowsOverlay,
          toolbar: disableToolbar ? undefined : Toolbar || CustomToolbar,
          columnMenu: CustomColumnMenu,
          columnsPanel: CustomColumnsPanelComponent,
          footer: CustomFooter,
          columnMenuSortAscendingIcon: () => (
            <FontAwesomeIcon icon={faArrowUp} className={classes.icon} />
          ),
          columnMenuSortDescendingIcon: () => (
            <FontAwesomeIcon icon={faArrowDown} className={classes.icon} />
          ),
          columnMenuPinLeftIcon: () => (
            <FontAwesomeIcon
              icon={faThumbTack}
              className={clsx(classes.pinToLeftIcon, classes.icon)}
            />
          ),
          columnMenuPinRightIcon: () => (
            <FontAwesomeIcon
              icon={faThumbTack}
              className={clsx(classes.pinToRightIcon, classes.icon)}
            />
          ),
          columnMenuHideIcon: () => <FontAwesomeIcon icon={faEyeSlash} className={classes.icon} />,
        }}
        slotProps={{
          columnMenu: {
            className: classes.columnMenu,
          },
        }}
        disableColumnFilter
        disableColumnMenu={false}
      />
    </div>
  );
};

export default inject('settingStore')(observer(DataGridInfiniteScroll));
