import moment from 'moment-timezone';
import { AxiosResponse } from 'axios';
import UAParser from 'ua-parser-js';

export enum EDateFormat {
  DEFAULT = 'MMM DD, YYYY',
  FULL_MONTH_DAY = 'MMMM DD',
  DATE_LONG = 'MM/DD/YYYY',
  DATE_TIME_FULL = 'MMM DD, YYYY h:mm A',
  TIME = 'hh:mm A',
  DATE_ISO = 'YYYY-MM-DD',
}

export function downloadCsvFile(data: any, outputFileName: string) {
  const downloadLink = document.createElement('a');
  const blob = new Blob(['\ufeff', data]);
  const url = URL.createObjectURL(blob);
  downloadLink.href = url;
  downloadLink.download = `${outputFileName}`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

export function formatGridTitleDate(date: string | undefined) {
  return date && moment(date).format('MMM, Do YYYY');
}

export enum DATE_TYPE {
  FULL = 'FULL',
  DATE = 'DATE',
  TIME = 'TIME',
}

const getFormattedDateTime = (date: moment.Moment, type: DATE_TYPE, formatString?: string) => {
  switch (type) {
    case DATE_TYPE.FULL:
      return date.format(formatString ? formatString : EDateFormat.DATE_TIME_FULL);
    case DATE_TYPE.DATE:
      return date.format(formatString ? formatString : EDateFormat.DEFAULT);
    case DATE_TYPE.TIME:
      return date.format(formatString ? formatString : EDateFormat.TIME);
  }
};

/**
 * This function returns formatted date string. Based on type it can return
 * either date, time or datetime. Default formats: Date: 'MMM DD', Time: 'hh:mm A', DateTime: 'MMM DD, YYYY, hh:mm A'
 * @param date date string.
 * @param formatString custom override for datetime string.
 * @param type enum of type DATE_TYPE. Defaults to DATE.
 */
export function formatDateTimeInEST(
  date?: string,
  type: DATE_TYPE = DATE_TYPE.DATE,
  formatString?: string,
) {
  if (!date) return;
  let dateInEST = moment(date).tz('America/New_York');
  return getFormattedDateTime(dateInEST, type, formatString);
}

/**
 * This function returns formatted date string converted to local time in UTC format.
 * Based on type it can return either date, time or datetime. Default formats: Date: 'MMM DD', Time: 'hh:mm A', DateTime: 'MMM DD, YYYY, hh:mm A'
 * @param date date string.
 * @param formatString custom override for datetime string.
 * @param type enum of type DATE_TYPE. Defaults to DATE.
 */
export function formatLocalDateTimeInUTC(
  date?: string,
  type: DATE_TYPE = DATE_TYPE.DATE,
  formatString?: string,
) {
  if (!date) return;
  let dateInUTC = moment(date).utc();
  return getFormattedDateTime(dateInUTC, type, formatString);
}

/**
 * This function returns formatted date string converted to local time.
 * Based on type it can return either date, time or datetime. Default formats: Date: 'MMM DD', Time: 'hh:mm A', DateTime: 'MMM DD, YYYY, hh:mm A'
 * @param date date string.
 * @param formatString custom override for datetime string.
 * @param type enum of type DATE_TYPE. Defaults to DATE.
 */
export function formatLocalDateTime(
  date?: string,
  type: DATE_TYPE = DATE_TYPE.DATE,
  formatString?: string,
) {
  if (!date) return;
  let dateInLocale = moment(date);
  return getFormattedDateTime(dateInLocale, type, formatString);
}

//** Extracting filename from response headers for csv reports */
export function getFileNameFromResponseHeaders(res: AxiosResponse): string {
  const headerLine = res.headers['content-disposition'] || res.headers['Content-Disposition'];
  const startFileNameIndex = headerLine.indexOf('"') + 1;
  const endFileNameIndex = headerLine.lastIndexOf('"');
  const filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
  return filename;
}

/**
 *
 * @param timestamp UTC timestamp
 * @returns seconds until timestamp 'expires' - until current time is the same as timestamp
 */
export function getSecondsUntilExpiration(timestamp?: string) {
  if (!timestamp) return 0;
  // Create moment objects for the given timestamp and current time
  const expirationTime = moment(timestamp);
  const currentTime = moment();

  // Calculate the duration between the two moments
  const duration = moment.duration(expirationTime.diff(currentTime));

  // Check if the timestamp has already expired
  if (duration.asMilliseconds() <= 0) {
    return 0;
  } else {
    // Calculate seconds remaining
    const secondsRemaining = Math.floor(duration.asSeconds());
    return secondsRemaining;
  }
}

/**
 * Decamelizes a string with/without a custom separator (underscore by default).
 *
 * @param str String in camelcase
 * @param separator Separator for the new decamelized string.
 */
export function decimalize(str: string, separator: string) {
  separator = typeof separator === 'undefined' ? '_' : separator;

  const separated = str
    .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
    .toLowerCase();

  return separated
    .split(' ')
    .map((it) => it.charAt(0).toUpperCase() + it.slice(1))
    .join(' ');
}

/**
 * @param precision how many decimal places come out
 * @param divider number of people for distribution
 * @param numerator amount for distribution
 * @returns an array of equally distributed values between dividers
 */
export const distribute = (precision: number, divider: number, numerator: number): number[] => {
  const arr = [];
  while (divider > 0) {
    const amount =
      Math.round((numerator / divider) * Math.pow(10, precision)) / Math.pow(10, precision);
    arr.push(amount);
    numerator -= amount;
    divider--;
  }
  return arr.sort((a, b) => b - a);
};
/**
 *
 * @param str
 * @returns a humanized capitalized string
 */
export function humanize(str: string) {
  const frags = str.split('_');
  for (let i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

/**
 *
 * @param str
 * @returns capitalized str string
 */
export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * This function geneates a key based on given inputs
 */
export function createKey(arg1: string | number, arg2: string | number) {
  return `${arg1}-${arg2}`;
}

/**
 * This function gets index value of an enum for a given value
 * @param value enum value
 * @param obj enum object
 * @returns index of enum for given value
 */
export function getKeyByValue(value: string, obj: any) {
  return Object.values(obj).indexOf(value);
}

/**
 * @param arr array of string values
 * @param value string value
 * @returns true if array includes given value, otherwise returns false
 */
export function isValueInArray(arr: string[], value: string): boolean {
  return arr.includes(value);
}

/**
 * Function that checks user agent's OS string.
 * @returns true if device type is mobile, otherwise returns false
 */
export function isMobileDevice() {
  const uaString = navigator.userAgent;
  let parser = new UAParser(uaString);
  return parser.getDevice().type?.toLocaleLowerCase().includes('mobile');
}

/**
 *
 * @param url URL to change to deep link
 * @param schema Schema to use eg. tippy://
 * @returns Deep link URL
 */
export function generateDeepLink(url: string, schema: string) {
  return url.replace(/^[a-zA-Z]{3,5}:\/{2}[a-zA-Z0-9_.:-]+/, schema);
}

/**
 * @param value any value
 * @returns value or N/A string
 */
export function valueOrNA(value: any) {
  return value || 'N/A';
}

/**
 * @param firstName First name
 * @param lastName Last name
 * @param placeholder Placeholder to show if first and last name params are undefined
 */
export function getFullName(firstName?: string, lastName?: string, placeholder?: string) {
  const fullName = [firstName, lastName].filter((s) => s).join(' ');
  if (!fullName && placeholder) return placeholder;
  return fullName;
}

/**
 *
 * @param names String array of query param names
 * @returns query param object
 */
export function getQueryParams(names: string[]) {
  let params: { [key: string]: string | null } = {};

  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  names.forEach((name) => {
    params[name] = queryParams.get(name);
  });

  return params;
}

/**
 * This method  splits label and suffix by empty spaces and then joins them with dashes.
 * @param label Eg. label of a button
 * @param suffix Value to be appended to label
 * @returns Kebab case string
 */
export function generateDataCy(label: string, suffix: string) {
  return [...label.split(' '), ...suffix.split(' ')].join('-').toLowerCase();
}

/**
 * Formats phone number for sending to backend
 * @param phoneNumber Phone number eg. +1 (432) 222-2222
 */
export function unFormatPhoneNumber(phoneNumber?: string) {
  /* 
  First we remove '+1' if it exists. If '+1' does not exist, remove all characters except numbers and + sign
  (SI phone numbers need to keep +386 country code) 
  */
  if (!phoneNumber) return undefined;
  return phoneNumber.replace(/^\+1/, '').replace(/[^\d+]/g, '');
}

export const capitalized = (word: string) => {
  return word[0].toUpperCase() + word.substr(1).toLowerCase();
};

/**
 * @param phoneNumber string to be formatted
 * @returns (xxx) xxx-xxxx
 */
export function formatPhoneNumber(phone = '') {
  // If there is no phone passed, return null
  if (!phone) {
    return null;
  }

  // Check if phone is already formatted with US or Slovenian format
  const usFormatted = /^\(\d{3}\) \d{3}-\d{4}$/;
  const sloFormatted = /^\+386 \(\d{2}\) \d{3}-\d{3}$/;

  if (usFormatted.test(phone) || sloFormatted.test(phone)) {
    return phone; // Return as is if already formatted
  }

  // Remove all non-digit characters (like +, spaces, parentheses, dashes, etc.)
  const cleaned = phone.replace(/\D/g, '');

  // Format US number: +1XXXXXXXXXX => (XXX) XXX-XXXX
  if (cleaned.startsWith('1') && cleaned.length === 11) {
    // Remove the leading 1 for US numbers
    const usNumber = cleaned.slice(1);
    return `(${usNumber.slice(0, 3)}) ${usNumber.slice(3, 6)}-${usNumber.slice(6)}`;
  }

  // Format Slovenian number: +386XXXXXXXXX => +386 (XX) XXX-XXX
  if (cleaned.startsWith('386') && cleaned.length === 11) {
    const sloNumber = cleaned.slice(3);
    return `+386 (${sloNumber.slice(0, 2)}) ${sloNumber.slice(2, 5)}-${sloNumber.slice(5)}`;
  }

  // If not a valid US or Slovenian number, return as is
  return phone;
}
