import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import Title, { ETitleVariant } from 'components/Title/Title/Title';
import DashboardLayout from 'containers/DashboardLayout';
import usePermissions from './usePermissions';

const Permissions = () => {
  const { accountName, refetchKey, actions, generateColumns, listManagerPermissions } =
    usePermissions();

  return (
    <DashboardLayout>
      <Title mb={3} variant={ETitleVariant.PAGE}>
        Manager Permissons
      </Title>

      <DataGridInfiniteScroll
        name={accountName}
        refetchKey={refetchKey}
        actions={actions}
        disableToolbarColumnsButton
        columns={generateColumns()}
        fetch={listManagerPermissions}
      />
    </DashboardLayout>
  );
};

export default Permissions;
