import AccountDetails from 'containers/AccountDetails';

import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import AnonRoute from './AnonRoute';
import AuthRoute from './AuthRoute';
import ScrollToTop from './ScrollToTop';
import Accounts from 'containers/Accounts';
import AccountSignup from 'containers/AccountSignup';
import AffiliateAccountSignup from 'containers/AccountSignup/AffiliateSignup';
import SelfSignup from 'containers/AccountSignup/SelfSignup';
import Activity from 'containers/Activity';
import Affiliates from 'containers/Affiliates';
import Alerts from 'containers/Alerts';
import BankPrompt from 'containers/BankPrompt';
import Campaign from 'containers/Campaign';
import Carts from 'containers/Carts';
import ChangeEmail from 'containers/ChangeEmail';
import Checkout from 'containers/Checkout';
import ChunkDetails from 'containers/ChunkDetails';
import ConfirmEmail from 'containers/ConfirmEmail';
import Conversion from 'containers/Conversion';
import Conversions from 'containers/Conversions';
import CustomerPayments from 'containers/CustomerPayments';
import Customers from 'containers/Customers';
import DashboardHome from 'containers/DashboardHome';
import Devices from 'containers/Devices';
import DevicesShipments from 'containers/DevicesShipments';
import ExternalAppLogin from 'containers/ExternalAppLogin';
import ForgotPassword from 'containers/ForgotPassword';
import InfoDisplay from 'containers/InfoDisplay';
import InitiationDetails from 'containers/InitiationDetails';
import Inquiry from 'containers/Inquiry';
import InviteUsers from 'containers/InviteUsers';
import KioskPrint from 'containers/KioskPrint';
import Leads from 'containers/Leads';
import LiabilityLedger from 'containers/LiabilityLedger';
import LinkBank from 'containers/LinkBank';
import LocationDetails from 'containers/LocationDetails';
import Locations from 'containers/Locations';
import LocationSelection from 'containers/LocationSelection';
import LocationsMap from 'containers/LocationsMap';
import Login from 'containers/Login';
import MergeUsers from 'containers/MergeUsers';
import MyAccount from 'containers/MyAccount';
import PageNotFound from 'containers/PageNotFound';
import Payments from 'containers/Payments';
import PaymentTips from 'containers/PaymentTips';
import PayoutsTransactions from 'containers/PayoutsTransactions';
import PendingTips from 'containers/PendingTips';
import Performance from 'containers/Performance';
import PersonalInfo from 'containers/PersonalInfo';
import PersonalInfo2 from 'containers/PersonalInfo2';
import ProfessionChoice from 'containers/ProfessionChoice';
import ProfilePicture from 'containers/ProfilePicture';
import Reconciliation from 'containers/Reconciliation';
import Refunds from 'containers/Refunds';
import Reports from 'containers/Reports';
import ResetPassword from 'containers/ResetPassword';
import RevenueOverview from 'containers/Revenue';
import LocationRevenue from 'containers/Revenue/LocationRevenue';
import StateRevenue from 'containers/Revenue/StateRevenue';
import Savings from 'containers/Savings';
import SignUp from 'containers/SignUp';
import SignUpSuccess from 'containers/SignUpSuccess';
import ReportDownload from 'containers/Tips/ReportDownload';
import TipsContainer from 'containers/Tips/TipsContainer';
import TipsOverview from 'containers/TipsOverview/TipsOverview';
import TipsReport from 'containers/TipsReport';
import UpdateCreditCard from 'containers/UpdateCreditCard';
import UserDetails from 'containers/UserDetails';
import Users from 'containers/Users';
import WebViewRedirect from 'containers/WebViewRedirect';

import PlaidRedirect from '../containers/Plaid/PlaidRedirect';
import Integrations from '../containers/Integrations/Integrations';
import Deposits from 'containers/Deposits/Deposits';
import RevShare from 'containers/RevShare/RevShare';
import Draw from 'components/Draw/Draw';
import Transactions from 'components/Draw/Balances/Transactions';
import PartnerDetails from '../containers/RevShare/Partners/PartnerDetails';
import RosyCallback from '../containers/Callbacks/RosyCallback';
import DinggCallback from '../containers/Callbacks/DinggCallback';

import { paths } from 'routes';
import Referrals from 'containers/Referrals/Referrals';
import { ACL, EManagerPermission } from 'types';
import PhoneNumber from 'containers/PhoneNumber/PhoneNumber';
import { EFeatureFlags } from 'models';
import { UserScopes } from 'stores';
import InvoiceReviewPage from 'containers/InvoiceReviewPage/InvoiceReviewPage';
import Transfers from 'containers/Transfers';
import Home from 'containers/Home/Home';
import Permissions from 'containers/Permissions';

/**
 * This component defines the routes for the whole project.
 */
const Routes = () => {
  // @ts-ignore
  const history: History<unknown> = createBrowserHistory();
  return (
    <Router history={history}>
      <ScrollToTop>
        <Switch>
          <Route path="/" exact>
            <Redirect to={paths.root()} />
          </Route>
          <Route exact path={paths.savings()} component={Savings} />
          <AuthRoute path={paths.root()} exact component={DashboardHome} />
          <AuthRoute path={paths.acceptInvitations()} exact component={DashboardHome} />
          <AuthRoute path={paths.accounts()} exact component={Accounts} scopes={['admin']} />
          <AuthRoute
            path={paths.adminAccountSignup().root()}
            scopes={['admin']}
            component={AccountSignup}
          />
          <AuthRoute
            path={paths.affiliateAccountSignup().root()}
            component={AffiliateAccountSignup}
          />
          <AuthRoute
            exact
            path={paths.accountDetails(`:accountId`).permissions()}
            component={Permissions}
            scopes={[UserScopes.ADMIN, UserScopes.OWNER]}
          />
          <AuthRoute
            exact
            path={paths.accountDetails(`:accountId`).updateCard()}
            component={UpdateCreditCard}
            scopes={['admin', 'owner']}
          />
          <AuthRoute
            path={paths.accountDetails(`:accountId`).root()}
            component={AccountDetails}
            scopes={['admin', 'owner']}
          />
          <AuthRoute
            path={paths.QRCodeForPrint()}
            component={KioskPrint}
            scopes={['admin', 'owner', 'manager']}
          />
          <AuthRoute
            path={paths.locations()}
            exact
            component={Locations}
            scopes={['admin', 'owner', 'global_owner']}
          />
          <AuthRoute path={paths.locationMap()} exact component={LocationsMap} scopes={['admin']} />
          <AuthRoute
            path={paths.locationDetails(`:locationId`)}
            exact
            component={LocationDetails}
            scopes={['admin', 'owner', 'global_owner']}
          />
          <AuthRoute
            path={paths.users().talent()}
            exact
            component={Users}
            scopes={['admin', 'owner', 'global_owner', 'manager']}
          />
          <AuthRoute
            path={paths.users().managers()}
            exact
            component={Users}
            scopes={['admin', 'owner', 'global_owner', 'manager']}
          />
          <AuthRoute
            path={paths.users().owners()}
            exact
            component={Users}
            scopes={['admin', 'owner', 'global_owner', 'manager']}
          />
          <AuthRoute
            path={paths.users().merge()}
            exact
            component={MergeUsers}
            scopes={['admin']}
            permissions={[ACL.MERGE_USERS]}
          />
          <AuthRoute
            path={paths.users().invite()}
            exact
            component={InviteUsers}
            scopes={['admin', 'manager', 'owner']}
          />
          <AuthRoute
            path={paths.userDetails(`:id`).root()}
            component={UserDetails}
            scopes={['admin', 'manager', 'owner', 'global_owner']}
          />
          <AuthRoute path={paths.downloadTipsReport()} exact component={ReportDownload} />

          <AuthRoute
            path={paths.tipsDynamics(`:accountId`)}
            component={TipsOverview}
            scopes={['admin']}
          />
          <AuthRoute
            path={paths.tips()}
            component={TipsOverview}
            managerPermissions={[EManagerPermission.VIEW_LOCATION_TIPS]}
            scopes={['admin', 'owner', 'global_owner', 'manager']}
          />
          <AuthRoute path={paths.myTips()} exact component={TipsContainer} scopes={['talent']} />
          <AuthRoute
            path={paths.reports()}
            component={Reports}
            managerPermissions={[EManagerPermission.VIEW_REPORTS]}
            scopes={['admin', 'owner', 'manager']}
          />
          <AuthRoute
            path={paths.analytics().tipsReport()}
            component={TipsReport}
            managerPermissions={[EManagerPermission.VIEW_STATS]}
            scopes={['admin', 'owner', 'global_owner', 'manager']}
          />
          <AuthRoute
            path={paths.analytics().revenueOverview()}
            exact
            component={RevenueOverview}
            scopes={['admin']}
          />
          <AuthRoute
            path={paths.analytics().stateRevenue(':stateLabel')}
            exact
            component={StateRevenue}
            scopes={['admin']}
          />
          <AuthRoute
            path={paths.analytics().locationRevenue(':locationId')}
            exact
            component={LocationRevenue}
          />
          <AuthRoute path={paths.analytics().activity()} component={Activity} scopes={['admin']} />
          <AuthRoute
            path={paths.analytics().performance().root()}
            component={Performance}
            scopes={['admin']}
          />
          <AuthRoute
            path={paths.analytics().conversion().root()}
            component={Conversion}
            scopes={['admin']}
          />
          <AuthRoute
            path={paths.analytics().conversion().root()}
            component={Conversion}
            scopes={['admin']}
          />
          <AuthRoute
            path={paths.customerPayments(':id')}
            exact
            component={CustomerPayments}
            scopes={['admin']}
          />
          <AuthRoute
            path={paths.paymentAccount(':accountId')}
            exact
            component={Payments}
            scopes={['admin']}
          />
          <AuthRoute exact scopes={['admin']} path={paths.payments()} component={Payments} />

          <AuthRoute
            path={paths.paymentTips(':paymentReference')}
            exact
            component={PaymentTips}
            scopes={['admin']}
          />
          <AuthRoute
            path={paths.payoutsTransactions(':id')}
            component={PayoutsTransactions}
            scopes={['admin', 'owner']}
          />
          <AuthRoute
            exact
            path={`${paths.deposits().chunkDetails(':id', ':chunkId')}`}
            component={ChunkDetails}
            scopes={['admin', 'owner']}
          />
          <AuthRoute
            path={paths.deposits().initiationDetails(':id')}
            component={InitiationDetails}
            scopes={['admin', 'owner']}
          />
          <AuthRoute
            path={paths.deposits().userPayouts()}
            component={Deposits}
            scopes={['admin', 'owner']}
          />
          <AuthRoute
            path={paths.deposits().partnerPayouts()}
            component={Deposits}
            scopes={['admin', 'owner']}
          />
          <AuthRoute
            path={paths.deposits().cards()}
            component={Deposits}
            scopes={['admin', 'owner']}
          />
          <AuthRoute
            path={paths.refunds().root()}
            exact
            component={Refunds}
            managerPermissions={[EManagerPermission.VIEW_REFUNDS]}
            scopes={['admin', 'global_owner', 'owner', 'manager']}
          />
          <AuthRoute path={paths.myAccount().root()} component={MyAccount} />
          <AuthRoute path={paths.sales().pendingCarts()} component={Carts} scopes={['admin']} />
          <AuthRoute path={paths.sales().completedCarts()} component={Carts} scopes={['admin']} />
          {/* <AnonRoute */}
          <Route
            path={paths.sales().cartsCompletedExternal(':invoiceId')}
            exact
            component={InvoiceReviewPage}
          />
          <AnonRoute path={paths.signIn()} exact component={Login} />
          <AnonRoute path={paths.forgotPassword()} exact component={ForgotPassword} />
          <AnonRoute path={paths.resetPassword(`:token`)} exact component={ResetPassword} />
          <AuthRoute path={paths.linkBank(':encodedUserData')} exact component={LinkBank} />
          <AuthRoute path={paths.integrations()} component={Integrations} scopes={['owner']} />
          {/* skip admin for now */}
          <AuthRoute
            path={paths.pendingTips().base()}
            component={PendingTips}
            scopes={['owner', 'manager']}
          />
          {/* Normal user signup */}
          <AnonRoute path={paths.signUp().root()} exact component={SignUp} />
          <AnonRoute
            path={paths.signUp().confirmEmailScreen()}
            exact
            render={(props) => <ConfirmEmail {...props} prevRoute={paths.signUp().root} />}
          />
          <AuthRoute path={paths.signUp().personalInfo()} exact component={PersonalInfo} />
          <AuthRoute path={paths.signUp().personalInfo2()} exact component={PersonalInfo2} />
          <AuthRoute path={paths.signUp().profession()} exact component={ProfessionChoice} />
          <AuthRoute path={paths.signUp().location()} exact component={LocationSelection} />
          <AuthRoute path={paths.affiliateSignUpOrg()} exact component={LocationSelection} />
          <AuthRoute path={paths.externalAppLogin()} exact component={ExternalAppLogin} />
          <AuthRoute path={paths.signUp().phoneNumber()} exact component={PhoneNumber} />
          <AuthRoute path={paths.phoneNumber().root()} exact component={PhoneNumber} />
          <AuthRoute path={paths.signUp().profilePicture()} exact component={ProfilePicture} />
          <AuthRoute path={paths.signUp().phoneNumber()} exact component={PhoneNumber} />
          <AuthRoute path={paths.phoneNumber().root()} exact component={PhoneNumber} />
          <AuthRoute path={paths.signUp().bank()} exact component={BankPrompt} />
          <AuthRoute path={paths.signUp().success()} exact component={SignUpSuccess} />
          {/* Manager signup */}
          <AnonRoute
            path={paths.managerSignUp()}
            exact
            render={(props: any) => <SignUp {...props} managerSignup />}
          />
          {/* Affiliate signup */}
          <AnonRoute
            path={paths.affiliateSignUp()}
            exact
            render={(props: any) => <SignUp {...props} affiliate />}
          />
          {/* Account self-signup */}
          <AnonRoute
            path={paths.register().root()}
            exact
            render={(props: any) => <SignUp {...props} accountSignup />}
          />
          <AnonRoute
            path={paths.register().confirmEmailScreen()}
            exact
            render={(props: any) => (
              <ConfirmEmail
                {...props}
                nextRoute={paths.register().personalInfo}
                prevRoute={paths.register().root}
              />
            )}
          />
          <AuthRoute
            path={paths.register().root()}
            render={(props: any) => <SelfSignup {...props} />}
          />
          {/* Marketing */}
          <AuthRoute path={paths.affiliates()} exact component={Affiliates} scopes={['admin']} />
          <AuthRoute path={paths.leads()} exact component={Leads} scopes={['admin']} />
          <AuthRoute path={paths.infoDisplay()} exact component={InfoDisplay} />
          <AuthRoute path={paths.conversions()} exact component={Conversions} scopes={['admin']} />
          <Route path={paths.plaidRedirectUri()} exact component={PlaidRedirect} />
          <Route path={paths.rosyCallback()} component={RosyCallback} />
          <Route path={paths.dinggCallback()} component={DinggCallback} />
          {/* Salons route */}
          <Route
            path={paths.salonCentricInquiry()}
            exact
            render={(props: any) => <Inquiry {...props} type={'salon'} />}
          />
          <Route
            path={paths.summitInquiry()}
            exact
            render={(props: any) => <Inquiry {...props} type={'summit'} />}
          />
          <Route
            path={paths.aobInquiry()}
            exact
            render={(props: any) => <Inquiry {...props} type={'aob'} />}
          />
          <Route path={paths.changeEmail()} exact component={ChangeEmail} />
          <Route path={paths.checkout(':cartId')} exact component={Checkout} />
          <AuthRoute
            path={paths.checkoutPreview(':cartId')}
            exact
            render={(props: any) => <Checkout {...props} hidePayment={true} />}
          />
          <AuthRoute path={paths.customers()} exact component={Customers} scopes={['admin']} />
          <AuthRoute path={paths.alerts()} exact component={Alerts} scopes={['admin']} />
          <AuthRoute
            path={paths.revshare().feeSchedule()}
            exact
            component={RevShare}
            scopes={['admin']}
          />
          <AuthRoute
            path={paths.revshare().partners()}
            exact
            component={RevShare}
            scopes={['admin']}
          />
          <AuthRoute
            path={paths.partnerDetails(':partnerId').root()}
            component={PartnerDetails}
            scopes={['admin']}
          />
          <Route path={paths.campaignsByCode(`:code`)} exact component={Campaign} />

          <AuthRoute scopes={['admin']} path={paths.devices().root()} component={Devices} />
          <AuthRoute
            scopes={['admin']}
            path={paths.devicesShipments(':hardwareId').root()}
            component={DevicesShipments}
          />

          <Route path="/web-view-redirect" component={WebViewRedirect} />
          <AuthRoute
            path={paths.draw().balances()}
            exact
            component={Draw}
            scopes={['admin', 'owner']}
          />
          <AuthRoute path={paths.draw().draws()} exact component={Draw} scopes={['admin']} />
          <AuthRoute
            path={paths.draw().transactions()}
            exact
            component={Transactions}
            scopes={['admin', 'owner']}
          />
          {/* Liability ledger */}
          <AuthRoute
            path={paths.liability()}
            exact
            component={LiabilityLedger}
            scopes={[UserScopes.ADMIN, UserScopes.OWNER, UserScopes.MANAGER]}
            permissions={[ACL.VIEW_LIABILITY]}
          />
          <AuthRoute
            path={paths.reconciliation().root()}
            component={Reconciliation}
            scopes={['admin', 'owner']}
          />
          <AuthRoute
            exact
            path={paths.referrals().root()}
            component={Referrals}
            featureFlagConfig={EFeatureFlags.REFERRAL}
            scopes={['owner', 'global_owner', 'talent', 'manager', 'affiliate', 'none']}
          />
          <Route path={paths.notFoundLogout} component={PageNotFound} />
          <AuthRoute path={paths.tipTransfers().root()} component={Transfers} scopes={['admin']} />
          <Route exact path={paths.home()} component={Home} />
          {/* 404 Route: */}
          <Route component={PageNotFound} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default Routes;
